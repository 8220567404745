import React from "react"
import appConfig from "src/appConfig"
import styled from "styled-components"

const textStyle = `
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-family: monospace;
  line-height: 1;
  padding: 0.33em 0.6em;
`

const Box = styled.aside`
  ${textStyle}
  bottom: 0;
  font-size: 12px;
  pointer-events: none;
  position: fixed;
  z-index: 1;
  right: 0;
  user-select: none;
  @media print {
    display: none;
  }
`

const logStyle = `
  font-size: 1.5em;
  margin: 0.5em 0;
  ${textStyle}
`

const Version: React.FC<{}> = () => {
  const { version, env } = appConfig
  ;(window as any).version = version
  const text = version ? `v${version} (${env})` : "unknown version"
  console.log(`%c${text}`, logStyle)

  if (env === "production") return null
  return <Box>{text}</Box>
}

export default Version
